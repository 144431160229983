body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url('https://fonts.googleapis.com/css?family=Open+Sans');

@font-face {
  font-family: 'MonserratBold';
  src: local('MonserratBold'), url(./App/Fonts/Monserrat/Montserrat-Bold.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'MonserratExtra';
  src: local('MonserratExtra'), url(./App/Fonts/Monserrat/Montserrat-ExtraBold.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'MonserratMedium';
  src: local('MonserratMedium'), url(./App/Fonts/Monserrat/Montserrat-Medium.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'GrottelRegular';
  src: local('GrottelRegular'), url(./App/Fonts/Grottel/Grottel-Regular.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'RawlineMedium';
  src: local('RawlineMedium'), url(./App/Fonts/Rawline/Rawline-Medium.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'RawlineBold';
  src: local('RawlineBold'), url(./App/Fonts/Rawline/Rawline-Bold.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'RawlineItalic';
  src: local('RawlineItalic'), url(./App/Fonts/Rawline/Rawline-MediumItalic.ttf) format('truetype');
  font-display: swap;
}